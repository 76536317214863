import "./index.scss";
import React, { useState } from "react";
import { Banner } from "../../components/Banner";
import team from "./images/team.png";
import teamGrid from "./images/team-grid.png";
import industry1 from "./images/industry1.jpg";
import industry2 from "./images/industry2.jpg";
import industry3 from "./images/industry3.jpg";
import industry4 from "./images/industry4.jpg";
import industry5 from "./images/industry5.jpg";
import industry6 from "./images/industry6.jpg";
import industry7 from "./images/industry7.jpg";

const itemTitles = [
  { name: "核心产品", harf: "box1" },
  { name: "核心团队", harf: "box2" },
  { name: "聚焦行业", harf: "box3" },
  { name: "服务体系", harf: "box4" },
];

export const PageAbout = () => {
  const [activeItem, setActiveItem] = useState(0);
  const scrollToAnchor = (anchorName) => {
    console.log(anchorName);
    if (anchorName) {
      // 找到锚点
      let anchorElement = document.getElementById(anchorName);
      // 如果对应id的锚点存在，就跳转到锚点
      if (anchorElement) {
        anchorElement.scrollIntoView({ block: "start", behavior: "smooth" });
      }
    }
  };
  return (
    <div className="page about" style={{ backgroundColor: "#f4f4f4" }}>
      <Banner title={"Cloudata云攻略"} />
      <div
        className="w-100 d-none d-sm-block"
        style={{ backgroundColor: "white" }}
      >
        <div className="show-menu bounded">
          {itemTitles.map((title, idx) => (
            <div
              key={title.name}
              className="item"
              // className={`item ${activeItem === idx ? "active" : ""}`}
              // onClick={() => {
              //   setActiveItem(idx);
              // }}
            >
              <a onClick={() => scrollToAnchor(title.harf)}>
                {itemTitles[idx].name}
              </a>
            </div>
          ))}
        </div>
      </div>
      {/* 核心产品 */}
      <div className="bounded" id="box1" style={{ marginTop: "40px" }}>
        <div
          className="padding-60"
          style={{
            backgroundColor: "white",
            textAlign: "left",
            color: "#666666",
            fontSize: "16px",
            lineHeight: "29px",
            fontWeight: "400",
          }}
        >
          Cloudata秉承“科技赋能客户成功”的理念，专注企业服务领域。公司从成立至今，同国内外在数据库领域，Hadoop大数据平台技术、人工智能领域的领先企业达成合作关系。公司主要合作厂商有：SelectDB（全球领先的实时数仓解决方案Doris商业版），Vertica（全球领先MPP数据库），Cloudera（全球领先大数据平台CDH/HDP/CDP的厂商，最流行Hadoop的商业发行版），INFINI
          Search （全球领先的大数据搜索平台）等。
        </div>
        <div className="padding-60">
          {[
            { title: "云攻略愿景", desc: "科技赋能客户成功" },
            {
              title: "云攻略使命",
              desc: "帮助客户洞察并挖掘数据价值，帮助客户把不可能变成可能",
            },
            { title: "云攻略价值观", desc: "客户成功，专业，诚信，坚持" },
          ].map((obj) => (
            <div key={obj.title} style={{ textAlign: "left" }}>
              <div
                className="slogan-title"
                style={{
                  color: "#181818",
                  fontSize: "24px",
                  lineHeight: "43px",
                  fontWeight: "600",
                  width: "188px",
                }}
              >
                {obj.title}
              </div>
              <div
                className="slogan-desc"
                style={{
                  color: "#3d3d3d",
                  fontSize: "20px",
                  lineHeight: "36px",
                  paddingTop: "3.5px",
                  fontWeight: "400",
                }}
              >
                {obj.desc}
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* 核心团队 */}
      <div
        id="box2"
        className="full-box"
        style={{
          backgroundColor: "white",
          marginTop: "40px",
          padding: "40px 0 60px 0",
        }}
      >
        <div className="bounded">
          <h2
            style={{
              marginTop: "60px",
              fontSize: "32px",
              lineHeight: "38px",
              color: "#181818",
              height: "45px",
              fontWeight: 600,
            }}
          >
            核心团队
          </h2>
          <img className="w-100 team" src={team} alt="核心团队" />
          <img className="w-100 team-grid" src={teamGrid} alt="核心团队" />
        </div>
      </div>
      {/* 聚焦行业 */}
      <div id="box3" className="bounded">
        <h2
          style={{
            marginTop: "60px",
            fontSize: "32px",
            lineHeight: "38px",
            color: "#181818",
            height: "45px",
            fontWeight: 600,
          }}
        >
          聚焦行业
        </h2>
        <div
          style={{
            margin: "10px 15px",
            fontSize: "14px",
            lineHeight: "24px",
            color: "#181818",
          }}
        >
          为客户提供数字化转型过程中与数据相关的技术产品和服务，我们相信科技是第一生产力，数据是信息科技的基石和宝库，通过数据可以把不可能变成可能。
        </div>
        <div style={{ marginTop: "30px", display: "flex", flexWrap: "wrap" }}>
          {[
            { title: "银行", src: industry1 },
            { title: "证券", src: industry2 },
            { title: "保险", src: industry3 },
            { title: "汽车", src: industry4 },
            { title: "高科技", src: industry5 },
            { title: "零售快销", src: industry6 },
            { title: "政府", src: industry7 },
          ].map((obj) => (
            <div key={obj.title} className="industry">
              <div className="block">
                <img src={obj.src} alt={obj.title} />
                <div className="title">{obj.title}</div>
              </div>
            </div>
          ))}
          <div className="industry">
            <div
              className="block"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                className="btn btn-primary text-white contact-btn"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
              >
                联系我们
              </div>
              <div
                className="btn btn-primary text-white contact-btn-mobile"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasExample"
              >
                联系我们
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 服务体系 */}
      <div
        id="box4"
        className="full-box"
        style={{
          backgroundColor: "white",
          marginTop: "60px",
          padding: "40px 0 60px 0",
        }}
      >
        <div className="bounded">
          <h2
            style={{
              marginTop: "20px",
              fontSize: "32px",
              lineHeight: "38px",
              color: "#181818",
              height: "45px",
              fontWeight: 600,
            }}
          >
            服务体系
          </h2>
          <div
            className="padding-60"
            style={{
              backgroundColor: "white",
              textAlign: "left",
              color: "#666666",
              fontSize: "16px",
              lineHeight: "29px",
              fontWeight: "400",
            }}
          >
            Cloudata将客户成功Customer
            Success落实到实处，通过现场或远程的方式，基于ITIL和ISO20000标准制定的服务管理流程，为用户定制满足不同技术需求的服务产品。客户成功服务中心设立7*24小时的400客户热线和线上服务门户，公司总经理，客户经理、CTO，数据架构师、咨询顾问，研发人员和客户成功经理CSM，7位一体全方面提供服务和客户关怀。根据客户的需求和实际情况，提供不同的服务套餐，从基础服务到高级服务，到专业定制化服务的不同服务。所有为用户提供服务的团队，全部都是由获得相应原厂商技术认证的工程师组成。专业和质量是我们的优势。
          </div>
        </div>
      </div>
    </div>
  );
};
