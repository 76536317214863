import { Banner } from "../../components/Banner"
import "./index.scss"
import icon1 from './images/icon1.png'
import icon2 from './images/icon2.png'
import icon3 from './images/icon3.png'
import icon4 from './images/icon4.png'

const renderData = [
  {
    icon: icon1,
    title: "数据仓库和大数据平台咨询",
    description: "Cloudata云攻略专业技术服务团队核心由知名咨询公司和原厂资深技术专家组成，拥有丰富的大数据项目经验。立足本地，咨询团队利用积累多年的大数据最佳实践，为客户提供大数据相关的战略规划、能力建设、组织设计、业务设计、平台规划、项目监理等咨询服务，帮助客户建立数据处理流程，发现业务价值，实现大数据实施路线。",
  },
  {
    icon: icon2,
    title: "数据仓库和大数据平台建设",
    description: "Cloudata云攻略专业技术服务团队根据客户的需求及未来发展规划，为客户规划、设计、部署大数据平台。解决方案包括：新一代数仓、湖仓一体、实时数仓、机器学习，MPP和大数据搜索平台等。在平台建设过程中，云攻略实施团队遵照项目管理、外包管理、开发管理、信息安全管理和质量管理等行业内规章制度进行项目过程管控。实施过程中的文档、代码等交付物均有严格的质量管控要求和管控体系。云攻略实施团队利用积累多年的大数据最佳实践，为客户构建先进、稳定、高效和易用的大数据平台，以支持客户大数据应用发展，并持续输出数据价值。",
  },
  {
    icon: icon3,
    title: "数据仓库和大数据平台运维支持",
    description: "Cloudata云攻略以原厂资深技术专家为核心组成的技术服务团队，为客户大数据平台提供远程或现场7*24小时本地化服务支持。服务内容包括：系统升级、迁移、调优、扩容、架构调整、漏洞修复、紧急救援、日常运维、定期巡检等。客户成功服务中心设立7*24 小时的400客户热线和线上服务门户，公司总经理，客户经理、CTO, 数据架构师、咨询顾问，研发人员和客户成功经理CSM，7位一体全方面提供服务和客户关怀。根据客户的需求和实际情况，提供不同的服务套餐，从基础服务到高级服务，到专业定制化服务的不同服务。所有为用户提供服务的团队，全部都是由获得相应原厂商技术认证的工程师组成。",
  },
  {
    icon: icon4,
    title: "数据仓库和大数据平台技术培训",
    description: "Cloudata云攻略拥有原厂资深培训师为核心的技术培训力量，根据客户的需求及未来发展规划，为客户定制和实施全面的培训计划，Cloudata云攻略培训尤其注重提高用户实操能力，为客户培养内部的专业大数据运维团队，使用户的技术人员及时掌握系统的应用及维护技巧，保证系统运行质量及系统稳定性。培训用户的关键用户，如项目管理人员、高级系统管理员，使其掌握系统的核心功能以及项目实施方法和步骤。确保系统的业务流程和系统功能的知识转移，使用户能正确、高效地使用系统。"
  }
]

export const PageService = () => {
  return <div className="page" style={{backgroundColor: "#f4f4f4"}}>
    <Banner title="技术服务" />
    <div className="page-service bounded text-start">
      {renderData.map((item) => {
        return <div className="bg-white p-4 p-md-5 my-5 mx-4 mx-md-0" key={item.title}>
          <img src={item.icon} alt="图标" width={64} height={64} />
          <div className={"service-title fs-3 fw-bold mt-4"}>{item.title}</div>
          <div className="service-description mt-4" style={{color: "#3D3D3D"}}>{item.description}</div>
        </div>
      })}
    </div>
  </div>
}