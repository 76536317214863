import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import index1 from "../images/index1.png";
import index8 from "../images/index8.png";
import index9 from "../images/index9.png";
import index10 from "../images/index10.png";
import index11 from "../images/index11.png";
import index26 from "../images/index26.png";
import index27 from "../images/index27.jpg";
import index28 from "../images/index28.jpg";
import index29 from "../images/index29.jpg";
import index30 from "../images/index30.jpg";
import index31 from "../images/index31.jpg";
import index32 from "../images/index32.jpg";
import index33 from "../images/index33.jpg";
import index34 from "../images/index34.jpg";
import index35 from "../images/index35.jpg";
import index36 from "../images/index36.jpg";
import index37 from "../images/index37.jpg";
import index38 from "../images/index38.jpg";
import index43 from "../images/index43.png";
import index44 from "../images/index44.png";
import index45 from "../images/index45.png";
import index46 from "../images/index46.png";
import icon1 from "../images/icon.png";
import icon2 from "../images/icon2.png";
import icon3 from "../images/icon3.png";
import icon4 from "../images/icon4.png";
import icon10 from "../images/icon10.png";
import icon11 from "../images/icon11.png";
import icon12 from "../images/icon12.png";
import icon13 from "../images/icon13.png";

const listTitles = [
  { name: "平台咨询", title: "实现大数据实施路线" },
  { name: "平台建设", title: "先进、稳定、高效和易用" },
  { name: "平台运维支持", title: "专业、本地化、7*24小时服务" },
  { name: "平台技术培训", title: "原厂资深培训师为核心" },
];
const itemTitles = [
  "实时数仓",
  "湖仓一体",
  "存算分离",
  "机器学习",
  "MPP大规模并行数据库",
  "大数据搜索平台和搜索网关",
];
const imglist = [
  { name: "平台咨询", img: index8 },
  { name: "平台建设", img: index9 },
  { name: "平台运维支持", img: index10 },
  { name: "平台技术培训", img: index11 },
];

const imageCaselist2 = [
  { name: "1", img: index43 },
  { name: "2", img: index44 },
  { name: "3", img: index45 },
  { name: "4", img: index46 },
];

const imageSrc2 = [index27, index28, index29, index30, index31, index32];
const srcMapping2 = Object.fromEntries(
  new Array(6).fill(0).map((v, idx) => [itemTitles[idx], imageSrc2[idx]])
);

export const IndexMobile = () => {
  const [activeItem, setActiveItem] = useState("实时数仓");
  const [activeList, setActivelist] = useState("");
  const [activeImg, setActiveImg] = useState("平台咨询");
  const [activeCaseImg, setActiveCaseImg] = useState("");
  const navigate = useNavigate();
  return (
    <div>
      <div className="banner2">
        <div className="title fs-2">
          <h1>科技赋能客户成功</h1>
          <p>
            我们相信科技是第一生产力，数据是信息科技的基石和宝库，通过数据可以把不可能变成可能。
          </p>
          <button
            className="btn btn-primary px-4 py-sm-1 py-lg-2 text-white d-block d-md-none"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasExample"
          >
            联系我们
          </button>
        </div>
      </div>
      <div className="show-menu2">
        {listTitles.map((title) => (
          <div
            key={title.name}
            className={`col-2 item ${
              activeList === title.name
                ? "menu2-active menu2-list"
                : "menu2-list"
            }`}
            onClick={() => {
              setActivelist(title);
            }}
          >
            <div
              className="listCss"
              onClick={() => {
                navigate(`/service`);
              }}
            >
              <p
                className={`${activeList === title.name ? "menu2-active" : ""}`}
              >
                <span>{title.name}</span>
                <br />
                {title.title}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className="image-box1">
        <div className="cloudata-title">
          <span
            onClick={() => {
              navigate(`/about`);
            }}
          >
            Cloudata云攻略
          </span>
          <h3>“帮助客户洞察并挖掘数据价值”</h3>
          <p>
            Cloudata云攻略是一家专业的数据库和大数据平台解决方案提供商和服务商。我们专注于为国内各个行业客户提供数据库和大数据相关的产品解决方案和全生命周期的技术服务。
          </p>
        </div>
        <div className="cloudata-img">
          <img className="image" src={index1} alt="" />
          <ul>
            <li>
              <img src={icon1} alt="" />
              <b>客户成功</b>
            </li>
            <li>
              <img src={icon2} alt="" />
              <b>专业</b>
            </li>
            <li>
              <img src={icon3} alt="" />
              <b>诚信</b>
            </li>
            <li>
              <img src={icon4} alt="" />
              <b>坚持</b>
            </li>
          </ul>
        </div>
      </div>
      <div className="solution2">
        <h2 className="container-fluid ">解决方案</h2>
        <div className="solution2-box">
          <div className="solution2-child">
            {itemTitles.map((title) => (
              <span
                key={title}
                className={`item2 ${activeItem === title ? "active1" : ""}`}
                onClick={() => {
                  setActiveItem(title);
                }}
              >
                {title}
              </span>
            ))}
          </div>
        </div>
        <div className="solve-box2">
          {activeItem === "实时数仓" ? (
            <div className="solve">
              <div className="solve-left">
                <img
                  className="image"
                  src={srcMapping2[activeItem]}
                  alt="解决方案"
                />
              </div>
              <div
                className="solve-right"
                onClick={() => {
                  navigate("/solution#" + activeItem);
                }}
              >
                <p>
                  基于Apache Doris内核打造的云原生实时数据仓库Select
                  DB是国内首款多云中立且开箱即用的云数仓。SelectDB Cloud
                  云原生数仓包含三个关键概念：组织、仓库和集群，它们作为产品设计的基石，构建起独立隔离、弹性可扩展的服务，帮助企业快速、安全地构建大数据分析业务的底座。
                </p>
              </div>
            </div>
          ) : activeItem === "湖仓一体" ? (
            <div className="solve">
              <div className="solve-left">
                <img
                  className="image"
                  src={srcMapping2[activeItem]}
                  alt="解决方案"
                />
              </div>
              <div
                className="solve-right"
                onClick={() => {
                  navigate("/solution#" + activeItem);
                }}
              >
                <p>
                  湖仓一体作为新型的开放式架构，打通了数据仓库和数据湖，并将两者的高性能及管理能力与灵活性融合，其底层支持多种数据类型并存，能够实现数据间的相互共享。并在上层可以通过统一封装的接口进行访问，同时支持实时查询和分析，让企业在进行数据治理使能更便利。Apache
                  Iceberg 是一种用于大型数据分析场景的开放表格式（Table
                  Format)。Iceberg
                  使用一种类似于SQL表的高性能表格式，其格式表单表可以存储10PB+数据，适配Spark、Trino、PrestoDB、Flink和Hive等计算引擎提高性能的读写和元数据管理功能。云攻略基于Cloudera
                  CDP和Iceberg打造出业界领先的可视化湖仓一体解决方案。
                </p>
              </div>
            </div>
          ) : activeItem === "存算分离" ? (
            <div className="solve">
              <div className="solve-left">
                <img
                  className="image"
                  src={srcMapping2[activeItem]}
                  alt="解决方案"
                />
              </div>
              <div
                className="solve-right"
                onClick={() => {
                  navigate("/solution#" + activeItem);
                }}
              >
                <p>
                  Cloudera 在跟 HortonWorks
                  合并后，便推出了新一代大数据平台Cloudera Data Platform (CDP)
                  Private Cloud (PvC)
                  Base。CDP平台引入了关键的新功能，实现资源隔离和多租户管理，计算优化节点将利用率提高到70%，自动缩放和自动暂停能力进一步降低了基础设施成本。自动扩展计算和存储资源。将多余的资源转移到需要它的应用上。
                </p>
              </div>
            </div>
          ) : activeItem === "机器学习" ? (
            <div className="solve">
              <div className="solve-left">
                <img
                  className="image"
                  src={srcMapping2[activeItem]}
                  alt="解决方案"
                />
              </div>
              <div
                className="solve-right"
                onClick={() => {
                  navigate("/solution#" + activeItem);
                }}
              >
                <p>
                  Cloudera CDP Machine Learning (CML)
                  使企业数据科学团队可以在整个数据生命周期中进行协作，可立即访问企业数据管道、可扩展的计算资源以及对首选工具的访问。简化将分析工作负载纳入生产的过程，并在整个企业范围内智能地管理机器学习用例。简化将分析工作负载纳入生产的过程，并在整个企业范围内智能地管理机器学习用例。
                </p>
              </div>
            </div>
          ) : activeItem === "MPP大规模并行数据库" ? (
            <div className="solve">
              <div className="solve-left">
                <img
                  className="image"
                  src={srcMapping2[activeItem]}
                  alt="解决方案"
                />
              </div>
              <div
                className="solve-right"
                onClick={() => {
                  navigate("/solution#" + activeItem);
                }}
              >
                <p>
                  Vertica提出了统一分析平台的建设思路，和湖仓一体架构类似，将数据存储到廉价的HDFS或对象存储，也可以使用Vertica内部格式存储，您可以通过标准的SQL操作所有的内容。并且Vertica可以进行企业级部署、也支持绝大多数公有云以及私有对象存储以及Hadoop，进行混合云的部署。Vertica原生的存算分离结构，可以弹性扩展或收缩计算资源、进行不同种类的数据处理进行资源隔离，提供了非常高的并发处理能力。
                </p>
              </div>
            </div>
          ) : (
            <div className="solve">
              <div className="solve-left">
                <img
                  className="image"
                  src={srcMapping2[activeItem]}
                  alt="解决方案"
                />
              </div>
              <div
                className="solve-right"
                onClick={() => {
                  navigate("/solution#" + activeItem);
                }}
              >
                <p>
                  INFINI Console
                  是一款非常轻量级的多集群、跨版本的搜索基础设施统一管控平台。通过对流行的搜索引擎基础设施进行跨版本、多集群的集中纳管，
                  企业可以快速方便的统一管理企业内部的不同版本的多套搜索集群。极限网关
                  (INFINI Gateway) 是一个面向 Elasticsearch
                  的高性能应用网关，它包含丰富的特性，使用起来也非常简单。
                </p>
              </div>
            </div>
          )}
          {/* <img className="image" src={srcMapping[activeItem]} alt="解决方案" /> */}
        </div>
      </div>
      <div className="technology2">
        <div>
          <h2>技术服务</h2>
        </div>
        <div className="technology3">
          <div className="technology-item">
            {imglist.map((title) => (
              <div
                key={title.name}
                className={`items ${activeImg === title.name ? "active2" : ""}`}
                onClick={() => {
                  setActiveImg(title.name);
                }}
              >
                <div
                  onClick={() => {
                    navigate(`/service`);
                  }}
                >
                  {title.name === "平台咨询" ? (
                    <div className="technology-title">
                      <h2>数据仓库和大数据平台咨询</h2>
                      <div>
                        Cloudata云攻略专业技术服务团队核心由知名咨询公司和原厂资深技术专家组成，拥有丰富的大数据项目经验。
                      </div>
                      <p>
                        <span>查看更多</span>
                      </p>
                    </div>
                  ) : title.name === "平台建设" ? (
                    <div className="technology-title">
                      <h2>数据仓库和大数据平台建设</h2>
                      <div>
                        Cloudata云攻略专业技术服务团队根据客户的需求及未来发展规划，为客户规划、设计、部署大数据平台。解决方案包括：新一代数仓、湖仓一体、实时数仓、机器学习，MPP和大数据搜索平台等。
                      </div>
                      <p>
                        <span>查看更多</span>
                      </p>
                    </div>
                  ) : title.name === "平台运维支持" ? (
                    <div className="technology-title">
                      <h2>数据仓库和大数据平台运维支持</h2>
                      <div>
                        Cloudata云攻略以原厂资深技术专家为核心组成的技术服务团队，为客户大数据平台提供远程或现场7*24小时本地化服务支持。服务内容包括：系统升级、迁移、调优、扩容、架构调整、漏洞修复、紧急救援、日常运维、定期巡检
                      </div>
                      <p>
                        <span>查看更多</span>
                      </p>
                    </div>
                  ) : (
                    <div className="technology-title">
                      <h2>数据仓库和大数据平台技术培训</h2>
                      <div>
                        Cloudata云攻略拥有原厂资深培训师为核心的技术培训力量，根据客户的需求及未来发展规划，为客户定制和实施全面的培训计划。
                      </div>
                      <p>
                        <span>查看更多</span>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="case2">
        <div className="solution">
          <h2 className="container-fluid ">客户案例</h2>
        </div>
        <div className="swiper">
          <div id="demo" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-indicators">
              <button
                type="button"
                data-bs-target="#demo"
                data-bs-slide-to="0"
                className="active"
              ></button>
              <button
                type="button"
                data-bs-target="#demo"
                data-bs-slide-to="1"
              ></button>
              <button
                type="button"
                data-bs-target="#demo"
                data-bs-slide-to="2"
              ></button>
              <button
                type="button"
                data-bs-target="#demo"
                data-bs-slide-to="3"
              ></button>
              <button
                type="button"
                data-bs-target="#demo"
                data-bs-slide-to="4"
              ></button>
              <button
                type="button"
                data-bs-target="#demo"
                data-bs-slide-to="5"
              ></button>
            </div>
            <div className="carousel-inner">
              <div
                className="carousel-item active"
                onClick={() => {
                  navigate(`/case/1`);
                }}
              >
                <div className="d-block">
                  <div className="swiper-list">
                    <div className="swiper-img">
                      <img src={index33} alt="" />
                    </div>
                    <div className="swiper-title">
                      <h2>金融客户实时数仓项目</h2>
                      <p>
                        <span></span>
                      </p>
                      <p>
                        依托 Doris 强大的查询性能，我们将把 Doris 架设在 Hive
                        数仓的上层，为特定场景进行查询加速，这样的架构建设起来成本很低，只需要完成数据从
                        Hive 数仓到 Doris
                        集群的导入适配，,直接复用已经建设好的数据血缘关系
                      </p>
                      <p>成果</p>
                      <p>
                        Doris
                        集群架构清晰简单，不依赖其他组件，数据模型简单，数据导入方式多样化且适配成本很低，使得项目可以快速完成前期的调研测试并在短时间内上线实施
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="carousel-item"
                onClick={() => {
                  navigate(`/case/2`);
                }}
              >
                <div className="d-block">
                  <div className="swiper-list">
                    <div className="swiper-img">
                      <img src={index34} alt="" />
                    </div>
                    <div className="swiper-title">
                      <h2>银行数据湖项目</h2>
                      <p>
                        <span></span>
                      </p>
                      <p>
                        Cloudera
                        数据湖集中所有客户信息；创建了一张代表消费者所有方面的“VIP卡”，包括之前的所有互动；纳入额外数据（社交媒体、情绪、交易历史、社交网站点赞等）以提高个性化
                      </p>
                      <p>成果</p>
                      <p>
                        增加收入和客户满意度；客户团队不断扩展消费者数据的 360
                        度视图；推荐引擎，根据之前的购买和行为推荐更合适的产品
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="carousel-item"
                onClick={() => {
                  navigate(`/case/3`);
                }}
              >
                <div className="d-block">
                  <div className="swiper-list">
                    <div className="swiper-img">
                      <img src={index35} alt="" />
                    </div>
                    <div className="swiper-title">
                      <h2>券商大数据分析平台</h2>
                      <p>
                        <span></span>
                      </p>
                      <p>
                        基于Cloudera
                        CDP搭建了端到端的数据采集，存储，解析和分析平台；采用Solr/Cloudera
                        Search, Flume,
                        Spark等各类技术来处理非机构化的日志数据；使用Impala技术来处理结构化的数据
                      </p>
                      <p>成果</p>
                      <p>
                        支撑海量的日志存储和分析；异常交易实时预警；提高了现有系统的管理和运营水平
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="carousel-item"
                onClick={() => {
                  navigate(`/case/4`);
                }}
              >
                <div className="d-block">
                  <div className="swiper-list">
                    <div className="swiper-img">
                      <img src={index36} alt="" />
                    </div>
                    <div className="swiper-title">
                      <h2>汽车车联网大数据平台</h2>
                      <p>
                        <span></span>
                      </p>
                      <p>
                        新能源车通过车联网模块采集到车联网平台。车联网平台将数据定时发送到整车厂后台；原始非结构化数据保存在HDFS，通过定时任务，将原始数据解析成结构化数据；应用服务器完成实时监控、报警，同时将数据转发至政府新能源汽车公共数据采集平台
                      </p>
                      <p>成果</p>
                      <p>
                        满足整车厂对于新能源车的实时监控需求；满足量产新能源车上市增长规模，实现线性扩展能力；满足政府对新能源汽车实时采集的相关规定。
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="carousel-item"
                onClick={() => {
                  navigate(`/case/5`);
                }}
              >
                <div className="d-block">
                  <div className="swiper-list">
                    <div className="swiper-img">
                      <img src={index37} alt="" />
                    </div>
                    <div>
                      <div className="swiper-title">
                        <h2>电信运营商数据分析系统</h2>
                        <p>
                          <span></span>
                        </p>
                        <p>
                          部署Vertica大规模并行处理（MPP），基于廉价的X86服务器集群，获得高性能的分析处理能力；Vertica查询优化器通过分析数据库的负载与常用数据分析的计算特性，调整库内数据的存储、压缩、分布方式，从而根据用户需求优化数据库的数据装载或查询性能；基于SQL语句和传统的JDBC和ODBC等程序接口，利于现有数据分析系统移植
                        </p>
                        <p>成果</p>
                        <p>
                          列式存储为大多数分析型数据库所采用，区别于传统的交易型数据库（如Oracle），按列高速查询，性能提升在50-1000倍；根据用户查询特性优化存储结构和查询算法，进一步提升查询性能（关联、分组等查询性能可再提高5-10倍）
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="carousel-item"
                onClick={() => {
                  navigate(`/case/6`);
                }}
              >
                <div className="d-block">
                  <div className="swiper-list">
                    <div className="swiper-img">
                      <img src={index38} alt="" />
                    </div>
                    <div className="swiper-title">
                      <h2>保险业客户大数据搜索平台</h2>
                      <p>
                        <span></span>
                      </p>
                      <p>
                        通过分析，集群性能应该没有问题，不过由于单个批次写入请求到达
                        Elasticsearch
                        之后需要重新再次按照主分片所在节点进行封装转发，而某保的业务索引分片个数太多，每个数据节点最终拿到的请求文档数太小，
                        客户端一次批次写入要拆分成几百次的小批次请求，并且由于短板原理，最慢的节点处理速度会拖慢整个批次写入的速度，从而造成集群总体吞吐的低下
                      </p>
                      <p>成果</p>
                      <p>
                        通过采用极限网关来作为中间加速层，该集团保单业务的索引重建速度由原来的
                        2-3 天都重建不完缩减到 20 分钟左右，每日增量 6
                        亿条数据的全部重建终于也可以快速完成
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="btnMe">
          <button
            className="btn btn-primary px-4 py-sm-1 py-lg-2 text-white d-block d-md-none"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasExample"
          >
            立即咨询
          </button>
        </div>
      </div>
      <div className="cooperate2">
        <div className="solution">
          <h2>合作伙伴</h2>
        </div>
        <div className="cooperate1">
          {imageCaselist2.map((title) => (
            <div
              key={title.name}
              className={`${
                activeCaseImg === title.name
                  ? "active3 cooperate-list"
                  : " cooperate-list"
              }`}
              onClick={() => {
                setActiveCaseImg(title.name);
              }}
            >
              <div>
                <div className="cooperate-content">
                  {title.name === "1" ? (
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("/partner");
                      }}
                    >
                      <h2>
                        <img src={icon10} alt="" />
                      </h2>
                      <p>
                        SelectDB Cloud 是全球领先的基于 Apache Doris
                        内核打造的全托管的云原生实时数据仓库
                      </p>
                      <h3>核心优势</h3>
                      <p>
                        <span>世界领先的强大性能</span>
                      </p>
                      <p>
                        <span>云原生、安全、开放</span>
                      </p>
                    </div>
                  ) : title.name === "2" ? (
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("/partner");
                      }}
                    >
                      <h2>
                        <img src={icon11} alt="" />
                      </h2>
                      <p>
                        Cloudera是全球商用Hadoop产品的领头羊，旗下CDH、HDP、CDP产品多年占居全球大数据领域较大的市场份额
                      </p>
                      <h3>核心优势</h3>
                      <p>
                        <span>稳定、可靠的Hadoop平台</span>
                      </p>
                      <p>
                        <span>庞大的客户和合作伙伴群体</span>
                      </p>
                    </div>
                  ) : title.name === "3" ? (
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("/partner");
                      }}
                    >
                      <h2>
                        <img src={icon12} alt="" />
                      </h2>
                      <p>
                        Vertica 是一款高级分析平台，将高性能 MPP
                        数据库和查询引擎的强大功能与高级分析和机器学习完美结合
                      </p>
                      <h3>核心优势</h3>
                      <p>
                        <span>高性能、统一查询和分析引擎</span>
                      </p>
                      <p>
                        <span>云原生存算分离、灵活的部署</span>
                      </p>
                    </div>
                  ) : (
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("/partner");
                      }}
                    >
                      <h2>
                        <img src={icon13} alt="" />
                      </h2>
                      <p>
                        专注于实时搜索与数据分析，旗下品牌极限实验室（INFINI
                        Labs）致力于打造极致易用的数据探索与分析体验
                      </p>
                      <h3>核心优势</h3>
                      <p>
                        <span>数据写入立马可查，无需刷新</span>
                      </p>
                      <p>
                        <span>支持超大规模集群，无限扩容</span>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
