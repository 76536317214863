import "./index.scss";

/** Banner 每个页面基本都会用到 */
export const Banner = ({ title }) => {
  return (
    <div className="banner">
      <div className="bounded banner-box">
        <div className="title fs-2 text-white">{title}</div>
        <div>
          <div
            className="btn btn-primary px-4 text-white d-block d-md-none"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasExample"
          >
            立即咨询
          </div>
          <div
            className="btn btn-primary px-4 text-white d-none d-md-inline-block"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
          >
            立即咨询
          </div>
        </div>
      </div>
    </div>
  );
};
