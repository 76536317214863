import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useMemo } from "react";
import "./index.scss";

const renderData = [
  // 1
  {
    title: "某金融客户实时数仓项目",
    subtitle: "核心产品：SelectDB",
    content: [
      {
        title: "业务痛点",
        points: [
          "金融科技业务的不断发展，对数据的安全性、准确性、实时性提出了更严格的要求。",
          "早期 Clickhouse 集群用于分析、标签业务场景，但是存在稳定性较低、运维复杂和表关联查询较慢等问题。",
          "业务中有部分报表数据分散存储在各类 DB 中，这也导致维护管理复杂度较高，亟需做出优化和重构。",
        ],
      },
      {
        title: "解决方案",
        points: [
          "依托 Doris 强大的查询性能，我们将把 Doris 架设在 Hive 数仓的上层，为特定场景进行查询加速，这样的架构建设起来成本很低，只需要完成数据从 Hive 数仓到 Doris 集群的导入适配，,直接复用已经建设好的数据血缘关系。",
          "Doris查询引擎支持多查询引擎动态切换的机制，通过识别查询数据的元信息对本次查询做自动的查询引擎（Doris/Presto/Spark/Hive）路由和故障切换。",
          "Doris日志告警监控平台监控 Doris 服务日志中容易识别但其他监控方式成本较高的监控、告警场景，是其他两种监控的补充。通过日志监控告警，我们能够准确识别数据导入任务的失败原因并能进行及时的推送通知。",
        ],
      },
      {
        title: "收益",
        points: [
          "Doris 集群架构清晰简单，不依赖其他组件，数据模型简单，数据导入方式多样化且适配成本很低，使得项目可以快速完成前期的调研测试并在短时间内上线实施",
          "Doris 集群作为目前公司 BI 工具的重要数据源，承载了相当一部分的报表分析业务，极大加速了报表分析的时效性",
          "Doris 具有完善的监控机制和审计机制，极大的降低了我们的运维工作",
        ],
      },
    ],
  },
  {
    title: "某银行数据湖项目",
    subtitle: "核心产品：Cloudera CDP",
    content: [
      {
        title: "业务痛点",
        points: [
          "无法了解消费者的方方面面",
          "用于网站流量、POS 交易和家庭服务的单独数据存储库",
          "无法提供消费者之前在线购买、店内购买和过去咨询项目的单一视图",
        ],
      },
      {
        title: "解决方案",
        points: [
          "Cloudera 数据湖集中所有客户信息",
          "创建了一张代表消费者所有方面的“VIP卡”，包括之前的所有互动",
          "纳入额外数据（社交媒体、情绪、交易历史、社交网站点赞等）以提高个性化",
        ],
      },
      {
        title: "收益",
        points: [
          "增加收入和客户满意度",
          "客户团队不断扩展消费者数据的 360 度视图",
          "推荐引擎，根据之前的购买和行为推荐更合适的产品",
        ],
      },
    ],
  },
  {
    title: "某券商大数据分析平台",
    subtitle: "核心产品：Cloudera CDP",
    content: [
      {
        title: "业务痛点",
        points: [
          "缺乏网络交易手机端和网页端日志的存储以及性能监控和分析",
          "用户交易行为分析滞后",
          "缺乏有效支撑系统运营故障分析",
        ],
      },
      {
        title: "解决方案",
        points: [
          "基于Cloudera CDP搭建了端到端的数据采集，存储，解析和分析平台",
          "采用Solr/Cloudera Search, Flume, Spark等各类技术来处理非机构化的日志数据",
          "使用Impala技术来处理结构化的数据",
        ],
      },
      {
        title: "收益",
        points: [
          "支撑海量的日志存储和分析",
          "异常交易实时预警",
          "提高了现有系统的管理和运营水平",
        ],
      },
    ],
  },
  {
    title: "某汽车车联网大数据平台",
    subtitle: "核心产品：Cloudera CDP",
    content: [
      {
        title: "业务痛点",
        points: [
          "新车型量产前，采集并保存路试车辆的行驶数据。每辆路试车辆每天产生的数据从50M到1G不等，取决于采集的数据类型和行驶时间。根据测试场景，可以远程配置需要采集的数据类型和采样频率。",
          "对路试车辆进行远程实时监控、发送测试指令、远程故障诊断和异常报警。将指定时间段的采样数据转换成PUMA格式文件，供研发中心对产品设计进行验证。",
          "对采样数据进行分析，产生报表。",
        ],
      },
      {
        title: "解决方案",
        points: [
          "新能源车通过车联网模块采集到车联网平台。车联网平台将数据定时发送到整车厂后台。",
          "原始非结构化数据保存在HDFS，通过定时任务，将原始数据解析成结构化数据。",
          "应用服务器完成实时监控、报警，同时将数据转发至政府新能源汽车公共数据采集平台。",
        ],
      },
      {
        title: "收益",
        points: [
          "满足整车厂对于新能源车的实时监控需求。",
          "满足量产新能源车上市增长规模，实现线性扩展能力。",
          "满足政府对新能源汽车实时采集的相关规定。",
        ],
      },
    ],
  },
  {
    title: "某电信运营商数据分析系统",
    subtitle: "核心产品：Vertica",
    content: [
      {
        title: "业务痛点",
        points: [
          "海量数据存储：年分析数据量达到PB级。包括：Counter数据、MR详单、CDR数据、IUPS数据、Gn数据、工参、投诉、设备告警、路测等其他数据。",
          "高速数据汇总及分析：保证按天、按月滚动的分析模块能够及时完成。",
          "少量的实时查询需求：从海量详单数据中迅速定位单个用户，例如用户轨迹查询。",
        ],
      },
      {
        title: "解决方案",
        points: [
          "部署Vertica大规模并行处理（MPP），基于廉价的X86服务器集群，获得高性能的分析处理能力。",
          "Vertica查询优化器通过分析数据库的负载与常用数据分析的计算特性，调整库内数据的存储、压缩、分布方式，从而根据用户需求优化数据库的数据装载或查询性能。",
          "基于SQL语句和传统的JDBC和ODBC等程序接口，利于现有数据分析系统移植。",
        ],
      },
      {
        title: "收益",
        points: [
          "列式存储为大多数分析型数据库所采用，区别于传统的交易型数据库（如Oracle），按列高速查询，性能提升在50-1000倍。",
          "根据用户查询特性优化存储结构和查询算法，进一步提升查询性能（关联、分组等查询性能可再提高5-10倍）。",
          "为不同用户（数据分析、实时查询、数据导入等）分配各自系统资源（CPU、内存等），拥有图形化的网页监控界面。",
        ],
      },
    ],
  },
  {
    title: "某保险业客户大数据搜索平台",
    subtitle: "核心产品：Infini Search",
    content: [
      {
        title: "业务痛点",
        points: [
          "该保险客户保单查询业务，通过将数据库的常用字段放到 Elasticsearch 里面，用来提升查询性能，集群部署在 14 台物理机上面，每个物理机上面部署了 4 个 Elasticsearch 实例， 整个集群约有 90 多亿条数据，索引主分片存储接近 5 TB，每天的增量更新数据大概在 6 亿条左右，由于业务上的特殊性，全国的所有的业务数据都存放在一个索引里面， 造成了单个索引达到了 210 个分片，批量重建的任务采用 Spark 任务来并行执行，平均的写入速度在 2000~3000 条/s 左右，一次增量重建时间可能需要 2~3 天， 业务数据的更新延迟较大，长时间的重建也会影响正常时间段的业务访问。该技术团队也尝试过直接Elasticsearch 层面和 Spark 写入端多轮的测试和调优，发现对整体的写入速度没有太大的提升。",
        ],
      },
      {
        title: "解决方案",
        points: [
          "通过分析，集群性能应该没有问题，不过由于单个批次写入请求到达 Elasticsearch 之后需要重新再次按照主分片所在节点进行封装转发，而某保的业务索引分片个数太多，每个数据节点最终拿到的请求文档数太小， 客户端一次批次写入要拆分成几百次的小批次请求，并且由于短板原理，最慢的节点处理速度会拖慢整个批次写入的速度，从而造成集群总体吞吐的低下。",
          "通过评估极限网关，发现极限网关具备提前拆分请求和合并请求的能力，通过提前拆分合并请求到以节点为单位的本地队列，然后通过队列消费程序写入到目标 Elasticsearch 集群，将随机的批次请求转换为顺序的精准投放。极限网关在收到 Spark 请求之后先落地到本地磁盘确保数据不丢失，同时极限网关能够本地计算每个文档与目标数据节点的对应关系。通过采用极限网关来接收 Spark 的写入请求，整个集群的写入吞吐显著提升，Spark 写数据只花了不到 15 分钟即任务运行结束，网关从收到请求到写完 Elasticsearch 也只花了 20 分钟，服务器的 CPU 资源也充分利用起来了， 各个节点的 CPU 利用率均达到 100%。",
        ],
      },
      {
        title: "收益",
        points: [
          "通过采用极限网关来作为中间加速层，该集团保单业务的索引重建速度由原来的 2-3 天都重建不完缩减到 20 分钟左右，每日增量 6 亿条数据的全部重建终于也可以快速完成， 索引写入 QPS 峰值也达到了 30 万+，大大缩短了索引重建周期，降低了数据延迟，增强了线上数据的一致性，确保了查询业务的正常使用。",
        ],
      },
    ],
  },
];

export const PageCaseDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const idx = useMemo(() => {
    return parseInt(id) - 1;
  }, [id]);

  useEffect(() => {
    if (idx < 0 || idx > renderData.length) {
      navigate("/cases", { replace: true });
    }
  }, [idx, navigate]);

  const data = useMemo(() => {
    return renderData[idx];
  }, [idx]);

  return (
    <div className="page">
      <div className="case-header">
        <h1 className="case-title">{data.title}</h1>
        <div className="case-subtitle">{data.subtitle}</div>
      </div>
      <div className="case-body text-start">
        {data.content.map((item, index) => (
          <div className="case-section" key={index}>
            <h2 className="case-section-title">{item.title}</h2>
            <ul className="case-section-list">
              {item.points.map((point, index) => (
                <li className="case-section-list-item" key={index}>
                  {point}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};
