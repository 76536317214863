import { Banner } from "../../components/Banner";
import { useState } from "react";
import "./index.scss";

const itemTitles = [
  { name: "SelectDB 实时数仓", harf: "SelectDB" },
  { name: "Cloudera 大数据混合平台", harf: "Cloudera" },
  { name: "Vertica 统一分析平台", harf: "Vertica" },
  { name: "INFINI Search", harf: "INFINI" },
];

const renderData = [
  {
    key: "SelectDB",
    imgKey: "1",
    name: "SelectDB实时数仓",
    description:
      "北京飞轮数据科技有限公司（简称：SelectDB）是一家专注于开源技术创新的云原生实时数据库厂商，公司总部位于北京。公司创始团队由原百度智能云初创人员和Apache Doris项目核心成员组成，在云计算/大数据/人工智能的产品、技术、商业化以及开源社区运营方面有丰富的经验。SelectDB的产品的目标是构建一个实时、统一的架构，成为企业的数据分析平台的统一入口。支持三大主流数据源的数据导入：数据库、数据湖、数据流，满足实时数据服务、交互数据分析、离线数据处理三大主流数据应用场景。Select DB是国内首款多云中立且开箱即用的云数仓，提供极致的性能和优良的易用性，支持主流的X64和ARM64架构，支持多种操作系统，其国产化生态包括统信、麒麟操作系统，兆芯、海光、鲲等处理器，并且Select DB源自Apache Doris，可以完美集成各种开源组件。",
    customerCount: 12,
    introduction: [
      {
        title: "SelectDB Cloud 介绍",
        href: `${process.env.PUBLIC_URL}/SelectDB Cloud 介绍.pdf`,
      },
    ],
    cases: [
      {
        title: "实时分析全面赋能金融业务",
        href: `${process.env.PUBLIC_URL}/SelectDB案例-实时分析全面赋能金融业务.pdf`,
      },
      {
        title: "小米亿级用户行为分析平台实践",
        href: `${process.env.PUBLIC_URL}/SelectDB案例-Apache Doris 在小米亿级用户行为分析平台的实践.pdf`,
      },
      {
        title: "腾讯音乐内容库数据平台架构演进实践",
        href: `${process.env.PUBLIC_URL}/SelectDB案例-从 ClickHouse 到 Apache Doris.pdf`,
      },
    ],
  },
  {
    key: "Cloudera",
    imgKey: "2",
    name: "Cloudera大数据混合平台",
    description:
      "Cloudera（肯睿）成立于2008年，由来自如于Google、Yahoo、Oracle和Facebook的技术高管联手创立。Cloudera是全球第一个企业级Apache Hadoop产品和服务提供商，无论在产品、专业服务、技术支持和培训方面都处于明显的业界领袖地位。Cloudera专注于Hadoop全球生态系统的建设，主导了最多Hadoop模块的开发工作，代码贡献量，bug修复数量，Hadoop committer数量稳居全球首位，是无可争议的Hadoop开源领导厂商。Cloudera旗下产品CDH、HDP、CDP多年占居全球大数据领域较大的市场份额。最新产品Cloudera Data Platform（CDP）是一个统一的平台，具有针对整个数据生命周期的可移植、可互操作的数据分析，以及在公共云、本地部署和边缘运行的分布式数据管理。借助于Cloudera企业版成熟的整体解决方案，企业可以放心将数据整合在CDP，进而专注于自己的业务能力。",
    customerCount: 54,
    introduction: [
      {
        title: "Cloudera介绍",
        href: `${process.env.PUBLIC_URL}/Cloudera_CDP_Brochure_CN_202105.pdf`,
      },
    ],
    cases: [
      {
        title: "荷兰合作银行",
        href: `${process.env.PUBLIC_URL}/Cloudera案例-荷兰合作银行.pdf`,
      },
      {
        title: "DBS星展银行",
        href: `${process.env.PUBLIC_URL}/Cloudera案例-DBS星展银行.pdf`,
      },
    ],
  },
  {
    key: "Vertica",
    imgKey: "3",
    name: "Vertica统一分析平台",
    description:
      "Vertica是一款基于列存储的MPP（massively parallel processing）架构的数据库。它可以支持存放多至PB（Petabyte）级别的结构化数据。Vertica是由关系数据库大师Michael Stonebraker(2014 年图灵奖获得者)所创建，于2011年被惠普收购并成为其核心大数据平台软件。Vertica是一款高级分析平台，专为应对当前数据驱动型世界的规模和复杂性而精心打造，将高性能MPP数据库和查询引擎的强大功能与高级分析和机器学习完美结合。Vertica提出了统一分析平台的建设思路，支持绝大多数公有云以及私有对象存储以及Hadoop，进行混合云的部署，Vertica原生的存算分离结构，可以弹性扩展或收缩计算资源、进行不同种类的数据处理进行资源隔离，提供了非常高的并发处理能力。",
    customerCount: 12,
    introduction: [
      {
        title: "Vertica介绍",
        href: `${process.env.PUBLIC_URL}/Vertica介绍.pdf`,
      },
    ],
    cases: [
      {
        title: "Vertica案例",
        href: `${process.env.PUBLIC_URL}/Vertica案例.pdf`,
      },
    ],
  },
  {
    key: "INFINI",
    imgKey: "4",
    name: "INFINI搜索引擎",
    description:
      "极限数据（北京）科技有限公司，是一家专注于实时搜索与数据分析的软件公司。旗下品牌极限实验室（INFINI Labs）致力于打造极致易用的数据探索与分析体验。INFINI Search平台兼容原生ES产品，做到统一纳管、统一编排发布，同时实现安全审计、限流限速、集群加速、查询分析及干预等高级功能，并提供完善的热迁移方案。INFINI Search对于业务场景更友好，关注搜索场景的优化，提供增强的安全能力，完整的备份与容灾能力，增强型中文分词，本地化的词库与拼音，并且适配国产操作系统和 CPU 芯片，实现信创兼容。",
    customerCount: 14,
    introduction: [
      {
        title: "INFINI Search介绍",
        href: `${process.env.PUBLIC_URL}/极限科技产品介绍.pdf`,
      },
    ],
    cases: [
      {
        title: "作业帮跨云集群的就近本地访问",
        href: `${process.env.PUBLIC_URL}/Infini案例-作业帮跨云集群的就近本地访问.pdf`,
      },
      {
        title: "某保险业务索引速度百倍提升",
        href: `${process.env.PUBLIC_URL}/Infini案例-某保险业务索引速度百倍提升.pdf`,
      },
    ],
  },
];

export const PagePartner = () => {
  const [active, setActive] = useState(0);
  const scrollToAnchor = (anchorName) => {
    console.log(anchorName);
    if (anchorName) {
      // 找到锚点
      let anchorElement = document.getElementById(anchorName);
      // 如果对应id的锚点存在，就跳转到锚点
      if (anchorElement) {
        anchorElement.scrollIntoView({ block: "start", behavior: "smooth" });
      }
    }
  };
  return (
    <div className="page">
      <Banner title="合作伙伴" />
      <div
        style={{
          borderBottom: "2px solid #ededed",
        }}
      >
        <div className="show-menu d-none d-md-flex bounded">
          {itemTitles.map((title, idx) => (
            <div
              key={title.name}
              className="item"
              // className={`item ${activeItem === idx ? "active" : ""}`}
              // onClick={() => {
              //   setActiveItem(idx);
              // }}
            >
              <a onClick={() => scrollToAnchor(title.harf)}>
                {itemTitles[idx].name}
              </a>
            </div>
          ))}
        </div>
      </div>
      {renderData.map((d) => (
        <div id={d.key} key={d.key} className="text-start bounded padding-40">
          <img
            src={process.env.PUBLIC_URL + "/partner/" + d.imgKey + ".jpg"}
            alt={d.name}
            className="partner-img"
          />
          <h3 className="partner-title">{d.name}</h3>
          <div className="partner-desc">{d.description}</div>

          <h3 className="partner-title">典型客户</h3>
          <div className="d-flex flex-wrap">
            {Array(d.customerCount)
              .fill(0)
              .map((_, idx) => (
                <div className="customer-img" key={idx}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/partner/" +
                      d.imgKey +
                      "-" +
                      (idx + 1) +
                      ".jpg"
                    }
                    alt="典型客户"
                  />
                </div>
              ))}
          </div>

          <h3 className="partner-title mt-40">产品介绍</h3>
          {d.introduction.map((a) => (
            <div
              key={a.title}
              style={{
                display: "inline-block",
                marginTop: "10px",
                whiteSpace: "nowrap",
              }}
            >
              <a
                key={a.title}
                className="text-primary py-1 px-2"
                href={a.href}
                target="_blank"
                style={{
                  backgroundColor: "#feede5",
                  cursor: "pointer",
                  textDecoration: "none",
                  marginRight: 20,
                  fontSize: "14px",
                }}
                rel="noreferrer"
              >
                {a.title}
              </a>
            </div>
          ))}
          <h3 className="partner-title mt-40">经典案例</h3>
          {d.cases.map((a) => (
            <div
              key={a.title}
              style={{
                display: "inline-block",
                marginTop: "10px",
                whiteSpace: "nowrap",
              }}
            >
              <a
                key={a.title}
                className="text-primary py-1 px-2 h6"
                href={a.href}
                target="_blank"
                style={{
                  backgroundColor: "#feede5",
                  cursor: "pointer",
                  textDecoration: "none",
                  marginRight: 20,
                  fontSize: "14px",
                }}
                rel="noreferrer"
              >
                {a.title}
              </a>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
