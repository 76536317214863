import bannerSrc from "../../assets/cloudata-logo.png";
import { Link, NavLink } from "react-router-dom";
import "./index.scss";

export const Navbar = () => {
  return (
    <nav className="navbar navbar-light navbar-expand-md bg-light">
      <div className="container-fluid">
        <a className="navbar-brand" href="#">
          <img src={bannerSrc} alt="banner" width="100" height="38" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasNavbar"
          aria-controls="offcanvasNavbar"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="offcanvas offcanvas-end"
          tabIndex="-1"
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
        >
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
              <img
                src={bannerSrc}
                alt="云攻略 banner"
                width="100"
                height="36"
              />
            </h5>
            <button
              type="button"
              className="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body">
            <ul className="navbar-nav justify-content-end flex-grow-1 pe-sm-3">
              <li className="nav-item" data-bs-dismiss="offcanvas">
                <NavLink to={"/"} className="nav-link">
                  首页
                </NavLink>
              </li>
              <li className="nav-item" data-bs-dismiss="offcanvas">
                <NavLink to={"solution"} className="nav-link">
                  解决方案
                </NavLink>
              </li>
              <li className="nav-item" data-bs-dismiss="offcanvas">
                <NavLink to={"service"} className="nav-link">
                  技术服务
                </NavLink>
              </li>
              <li className="nav-item" data-bs-dismiss="offcanvas">
                <NavLink to={"cases"} className="nav-link">
                  客户案例
                </NavLink>
              </li>
              <li className="nav-item" data-bs-dismiss="offcanvas">
                <NavLink to={"partner"} className="nav-link">
                  合作伙伴
                </NavLink>
              </li>
              <li className="nav-item" data-bs-dismiss="offcanvas">
                <NavLink to={"about"} className="nav-link">
                  关于我们
                </NavLink>
              </li>
            </ul>
            <div>
              <div
                className="btn btn-primary text-white align-self-end ms-auto d-block d-md-none"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasExample"
              >
                联系我们
              </div>
              <div
                className="btn btn-primary text-white align-self-end ms-auto d-none d-md-block"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
              >
                联系我们
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};
