import "./index.scss";
import { IndexWeb } from "./indexWeb";
import { IndexMobile } from "./indexMobile";
import React, { useState ,useEffect ,useCallback } from 'react';

export const Index = () => {
  let renderMessage 
  function useWinSize(){
    const [ size , setSize] = useState({
        width:document.documentElement.clientWidth,
    })

    const onResize = useCallback(()=>{
        setSize({
            width: document.documentElement.clientWidth,
        })
    },[])

    useEffect(()=>{
        window.addEventListener('resize',onResize)
        return ()=>{
            window.removeEventListener('resize',onResize)
        }
    },[])
    return size;
  }
  const size = useWinSize()
  if(size.width > 765) {
    renderMessage = (        
      <IndexWeb/>
     )
  } else {
    renderMessage = (   
      <IndexMobile/> 
    )
  }
  
  return (
    <div className="page">
      {renderMessage}
    </div>
  );
};
