import { Banner } from "../../components/Banner";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import img1 from "./images/case1.png";
import img2 from "./images/case2.png";
import img3 from "./images/case3.png";
import img4 from "./images/case4.png";
import img5 from "./images/case5.png";
import img6 from "./images/case6.png";
import { NavLink } from "react-router-dom";

const images = [img1, img2, img3, img4, img5, img6];

export const PageCases = () => {
  const navigate = useNavigate();
  return (
    <div
      className="page"
      style={{
        backgroundColor: "#f4f4f4",
      }}
    >
      <Banner title="客户案例" />
      <div
        className="bounded d-flex flex-wrap"
        style={{
          margin: "60px auto",
        }}
      >
        {images.map((src, idx) => (
          <div key={idx} className="case">
            <NavLink to={"/case/" + (idx + 1)}>
              <img src={src} alt="案例图片" className="w-100" />
            </NavLink>
          </div>
        ))}
      </div>
    </div>
  );
};
