import styles from "./index.module.scss";
import React, { useState, useEffect } from "react";
import { Banner } from "../../components/Banner";
import leftQuote from "./images/left-quote.png";
import rightQuote from "./images/right-quote.png";

const itemTitles = [
  "实时数仓",
  "湖仓一体",
  "存算分离",
  "机器学习",
  "MPP大规模并行数据库",
  "大数据搜索平台和搜索网关",
];

const renderData = [
  {
    title: "实时数仓",
    subtitle: "Real Time Data Warehouse",
    description:
      "随着各行业线上业务的快速发展，数据体量迅速增长，数据时效在业务运营中愈发重要，面对海量数据的众多应用，处理能力和实时性的更高要求，促进了实时数仓技术的快速发展。",
    solution:
      "基于Apache Doris内核打造的云原生实时数据仓库Select DB是国内首款多云中立且开箱即用的云数仓。SelectDB Cloud 云原生数仓包含三个关键概念：组织、仓库和集群，它们作为产品设计的基石，构建起独立隔离、弹性可扩展的服务，帮助企业快速、安全地构建大数据分析业务的底座。",
    advantage:
      "SelectDB 提供极致的性能和优良的易用性，支持主流的X64和ARM64架构，支持多种操作系统，其国产化生态包括统信、麒麟操作系统，兆芯、海光、鲲等处理器。并且Select DB源自Apache Doris，可以完美集成各种开源组件。Clouata云攻略已经使用SelectDB的产品为多家客户实现大数据平台上的实时数仓需求。",
  },
  {
    title: "湖仓一体",
    subtitle: "Lakehouse",
    description:
      "数据驱动决策正在加速推动数据存储的转变，各行业陆续跟进采用了数据湖存储各种形式和格式的原始数据，以帮助企业挖掘更多的数据价值，但数据湖采用新的原始数据存储和处理范式，缺乏构造和治理，会迅速沦为“数据沼泽”。同时，传统数据仓库由于其存储规范、易用、高性能的特点，是数据湖无法取代的。如何结合数据湖和传统数仓的优势，打造统一的企业级数据平台，“湖仓一体”应运而生。",
    solution:
      "湖仓一体作为新型的开放式架构，打通了数据仓库和数据湖，并将两者的高性能及管理能力与灵活性融合，其底层支持多种数据类型并存，能够实现数据间的相互共享。并在上层可以通过统一封装的接口进行访问，同时支持实时查询和分析，让企业在进行数据治理使能更便利。Apache Iceberg 是一种用于大型数据分析场景的开放表格式（Table Format)。Iceberg 使用一种类似于SQL表的高性能表格式，其格式表单表可以存储10PB+数据，适配Spark、Trino、PrestoDB、Flink和Hive等计算引擎提高性能的读写和元数据管理功能。云攻略基于Cloudera CDP和Iceberg打造出业界领先的可视化湖仓一体解决方案。",
    advantage:
      "它同时吸收了数据仓库和数据湖的优势，数据开发工程师、数据分析师和数据科学家可以在同一个数据存储中对数据进行操作，同时它也能对当前数据治理以及过去数据沼泽开荒带来更多的便利性。Clouata云攻略已经使用该方案为多家客户实现新一代的湖仓一体的需求。",
  },
  {
    title: "存算分离",
    subtitle: "Compute and Storage Separation",
    description:
      "Hadoop 的诞生改变了企业对数据的存储、处理和分析的过程，加速了大数据的发展，受到广泛的应用，给整个行业带来了变革意义的改变；随着云计算时代的到来，存算分离的架构受到青睐，企业开开始对 Hadoop 的架构进行改造。",
    solution:
      "Cloudera 在跟 HortonWorks 合并后，便推出了新一代大数据平台Cloudera Data Platform (CDP) Private Cloud (PvC) Base。CDP平台引入了关键的新功能，实现资源隔离和多租户管理，计算优化节点将利用率提高到70%，自动缩放和自动暂停能力进一步降低了基础设施成本。自动扩展计算和存储资源。将多余的资源转移到需要它的应用上。",
    advantage:
      "CDP平台存储集群无需安装技术引擎，计算集群不同的计算引擎可以对存储集群的同一数据表进行操作。Clouata云攻略已经使用该方案为多家客户实现大数据存储和计算集群的快速搭建和销毁，实现集群的弹性扩展和收缩。",
  },
  {
    title: "机器学习",
    subtitle: "Machine Learning",
    description:
      "机器学习是大数据分析强有力的工具。大数据融入机器学习是当前的一个重要IT趋势。机器学习应用程序可以分析大数据并得出自己的结论，从而改变应用程序的行为。机器学习正迅速证明其在各行业的价值。",
    solution:
      "Cloudera CDP Machine Learning (CML) 使企业数据科学团队可以在整个数据生命周期中进行协作，可立即访问企业数据管道、可扩展的计算资源以及对首选工具的访问。简化将分析工作负载纳入生产的过程，并在整个企业范围内智能地管理机器学习用例。简化将分析工作负载纳入生产的过程，并在整个企业范围内智能地管理机器学习用例。",
    advantage:
      "Cloudera CDP Machine Learning (CML) 使用云原生和强大的工具来部署、维护和监控模型，从而优化了整个企业的机器学习工作流程。内嵌的数据治理平台SDX已扩展到模型，可以治理和自动化模型编目，然后无缝移动结果以跨 CDP 体验进行协作，包括 Data Warehouse 和 Operational Database。Cloudata云攻略已经帮助国内部分金融机构应用机器学习来发现客户行为的模式，并提供定制的金融建议和产品。",
  },
  {
    title: "MPP大规模并行数据库",
    subtitle: "",
    description:
      "湖仓一体的出现试图去融合数仓和数据湖这两者之间的差异，从架构上看，湖仓一体将分析型数据库和数据湖统一构建，但数据需要重复对方，并且通过不同的接口对外提供数据服务，比如报表应用由数据仓库通过SQL的方式提供，高并发查询由hbase提供服务等等，造成湖仓一体的架构不能有效降低使用复杂度以及维护难度过高的问题。",
    solution:
      "Vertica提出了统一分析平台的建设思路，和湖仓一体架构类似，将数据存储到廉价的HDFS或对象存储，也可以使用Vertica内部格式存储，您可以通过标准的SQL操作所有的内容。并且Vertica可以进行企业级部署、也支持绝大多数公有云以及私有对象存储以及Hadoop，进行混合云的部署。Vertica原生的存算分离结构，可以弹性扩展或收缩计算资源、进行不同种类的数据处理进行资源隔离，提供了非常高的并发处理能力。",
    advantage:
      "无共享的MPP 架构和真正的列式数据库特性，使Vertica 拥有高性能、高扩展性、高压缩率、高健壮性的特点。与传统的解决方案相比，Vertica 可以以30%的成本，实现50 倍-1000 倍的性能提高。",
  },
  {
    title: "大数据搜索平台和搜索网关",
    subtitle: "",
    description:
      "实时大数据搜索分析，尤其是结构化和非结构化数据结合的场景和需求非常大。针对海量数据，搜索技术成为核心，目前 Elasticsearch 在此领域占据绝对的领导地位。但是Elasticsearch 现在已经不是开源软件了，SSPL、Elastic License、需商业授权。另外稳定性、扩展性问题很大。",
    solution:
      "INFINI Console 是一款非常轻量级的多集群、跨版本的搜索基础设施统一管控平台。通过对流行的搜索引擎基础设施进行跨版本、多集群的集中纳管， 企业可以快速方便的统一管理企业内部的不同版本的多套搜索集群。极限网关 (INFINI Gateway) 是一个面向 Elasticsearch 的高性能应用网关，它包含丰富的特性，使用起来也非常简单。",
    advantage:
      "INFINI Search 平台可以兼容现有的原生ES产品，做到统一纳管、统一编排发布，同时实现安全审计、限流限速、集群加速、查询分析及干预等高级功能，并提供完善的热迁移方案。",
  },
];

export const PageSolution = () => {
  const [activeItem, setActiveItem] = useState(0);
  useEffect(() => {
    if (window.location.hash) {
      const hash = decodeURIComponent(window.location.hash.replace("#", ""));
      const idx = itemTitles.findIndex((title) => title === hash);
      if (idx !== -1) {
        setActiveItem(idx);
      }
    }
  }, [])
  return (
    <>
      <Banner title={"解决方案"} />
      <div className={`${styles["page"]}`}>
        <div className={`${styles["show-menu"]} bounded`}>
          {itemTitles.map((title, idx) => (
            <div
              key={title}
              className={`${styles["item"]} ${
                activeItem === idx ? styles["active"] : ""
              }`}
              onClick={() => {
                setActiveItem(idx);
              }}
            >
              {itemTitles[idx]}
            </div>
          ))}
        </div>
        {/* 标题信息 */}
        <div className="w-100" style={{ backgroundColor: "#f4f4f4" }}>
          <div
            className="bounded text-center"
            style={{ paddingTop: 60, paddingBottom: 80 }}
          >
            <div className="fw-bold" style={{ fontSize: "32px" }}>
              {renderData[activeItem].title}
            </div>

            <div style={{ color: "#B3B3B3", marginBottom: 30 }}>
              {renderData[activeItem].subtitle}
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <img src={leftQuote} alt="left-quote" width={64} height={50} />
              <div
                className={`${styles["solution-title-text"]} d-inline-block`}
                style={{ margin: "0 10px" }}
              >
                {renderData[activeItem].description}
              </div>
              <img src={rightQuote} alt="right-quote" width={64} height={50} />
            </div>
          </div>
        </div>

        <div
          className="bounded bg-white text-md-start text-center px-5 px-md-0"
          style={{ paddingTop: 40, paddingBottom: 90 }}
        >
          <div className="fw-bold" style={{ fontSize: "24px" }}>
            解决方案
          </div>
          <hr color="#eeeeee" />
          <div style={{ color: "#3D3D3D" }}>
            {renderData[activeItem].solution}
          </div>
          <div className="fw-bold" style={{ fontSize: "24px", marginTop: 50 }}>
            方案优势
          </div>
          <hr color="#eeeeee" />
          <div style={{ color: "#3D3D3D" }}>
            {renderData[activeItem].advantage}
          </div>
        </div>
      </div>
    </>
  );
};
