import "./index.scss";
import { useNavigate } from "react-router-dom";
import top from "../../assets/top.png";
import bottom from "../../assets/bottom.png";
import code1 from "../../assets/code1.jpg";
import code2 from "../../assets/code2.png";
import React, { useState, useEffect, useCallback } from "react";

export const Footer = () => {
  const collapsed = true;
  const [isCollapsed, setIsCollapsed] = React.useState(collapsed);
  const [isCollapsed1, setIsCollapsed1] = React.useState(collapsed);
  const [isCollapsed2, setIsCollapsed2] = React.useState(collapsed);
  const [isCollapsed3, setIsCollapsed3] = React.useState(collapsed);
  const [isCollapsed4, setIsCollapsed4] = React.useState(collapsed);
  const navigate = useNavigate();
  let renderMessage;
  function useWinSize() {
    const [size, setSize] = useState({
      width: document.documentElement.clientWidth,
    });

    const onResize = useCallback(() => {
      setSize({
        width: document.documentElement.clientWidth,
      });
    }, []);

    useEffect(() => {
      window.addEventListener("resize", onResize);
      return () => {
        window.removeEventListener("resize", onResize);
      };
    }, []);
    return size;
  }
  const size = useWinSize();
  if (size.width > 765) {
    renderMessage = (
      <div className="footer-list bounded">
        <div className="col-sm-2">
          <ol>
            <li className="fist-li">解决方案</li>
            <li
              onClick={() => {
                navigate(`/solution`);
              }}
            >
              实时数仓
            </li>
            <li
              onClick={() => {
                navigate(`/solution`);
              }}
            >
              湖仓一体
            </li>
            <li
              onClick={() => {
                navigate(`/solution`);
              }}
            >
              存算分离
            </li>
            <li
              onClick={() => {
                navigate(`/solution`);
              }}
            >
              机器学习
            </li>
            <li
              onClick={() => {
                navigate(`/solution`);
              }}
            >
              MPP大规模并行数据库
            </li>
            <li
              onClick={() => {
                navigate(`/solution`);
              }}
            >
              大数据搜索平台和搜索网关
            </li>
          </ol>
        </div>
        <div className="col-sm-2">
          <ol>
            <li className="fist-li">技术服务</li>
            <li
              onClick={() => {
                navigate(`/service`);
              }}
            >
              数据仓库和大数据平台咨询
            </li>
            <li
              onClick={() => {
                navigate(`/service`);
              }}
            >
              数据仓库和大数据平台建设
            </li>
            <li
              onClick={() => {
                navigate(`/service`);
              }}
            >
              数据仓库和大数据平台运维支持
            </li>
            <li
              onClick={() => {
                navigate(`/service`);
              }}
            >
              数据仓库和大数据平台技术培训
            </li>
          </ol>
        </div>
        <div className="col-sm-2">
          <ol>
            <li className="fist-li">客户案例</li>
            <li
              onClick={() => {
                navigate(`/cases`);
              }}
            >
              金融客户实时数仓项目
            </li>
            <li
              onClick={() => {
                navigate(`/cases`);
              }}
            >
              银行数据湖项目
            </li>
            <li
              onClick={() => {
                navigate(`/cases`);
              }}
            >
              券商大数据分析平台
            </li>
            <li
              onClick={() => {
                navigate(`/cases`);
              }}
            >
              汽车车联网大数据平台
            </li>
            <li
              onClick={() => {
                navigate(`/cases`);
              }}
            >
              电信运营商数据分析系统
            </li>
            <li
              onClick={() => {
                navigate(`/cases`);
              }}
            >
              保险业客户大数据搜索平台
            </li>
          </ol>
        </div>
        <div className="col-sm-2">
          <ol>
            <li className="fist-li">合作伙伴</li>
            <li
              onClick={() => {
                navigate(`/partner`);
              }}
            >
              SelectDB实时数仓
            </li>
            <li
              onClick={() => {
                navigate(`/partner`);
              }}
            >
              Cloudera大数据混合平台
            </li>
            <li
              onClick={() => {
                navigate(`/partner`);
              }}
            >
              Vertica统一分析平台
            </li>
            <li
              onClick={() => {
                navigate(`/partner`);
              }}
            >
              INFINI Search
            </li>
          </ol>
        </div>
        <div className="col-sm-2">
          <ol>
            <li className="fist-li">关于我们</li>
            <li
              onClick={() => {
                navigate(`/about`);
              }}
            >
              核心产品
            </li>
            <li
              onClick={() => {
                navigate(`/about`);
              }}
            >
              核心团队
            </li>
            <li
              onClick={() => {
                navigate(`/about`);
              }}
            >
              聚焦行业
            </li>
            <li
              onClick={() => {
                navigate(`/about`);
              }}
            >
              服务体系
            </li>
          </ol>
        </div>
        <div className="last-input col-sm-2">
          <ol>
            <li className="fist-li">联系我们</li>
            <li className="input-li">
              <span>电话：</span>
              <b>400-001-1205</b>
            </li>
            <li className="input-li">
              <span>邮箱：</span>
              <b>sales@cdpdata.cn</b>
            </li>
            <li className="input-li-last">
              <span className="span-li">微信公众号：</span>
              <img src={code1} alt="" />
            </li>
          </ol>
        </div>
      </div>
    );
  } else {
    renderMessage = (
      <div>
        <div className="mt-3">
          <div id="accordion">
            <div className="card">
              <div className="card-header">
                <p
                  className="btn1"
                  data-bs-toggle="collapse"
                  href="#collapseOne"
                  onClick={() => setIsCollapsed1(!isCollapsed1)}
                >
                  <span>解决方案</span>
                  {isCollapsed1 ? (
                    <img src={bottom} alt="" width="16" height="16" />
                  ) : (
                    <img src={top} alt="" width="16" height="16" />
                  )}
                </p>
              </div>
              <div
                id="collapseOne"
                className="collapse"
                data-bs-parent="#accordion"
              >
                <div className="card-body">
                  <p
                    onClick={() => {
                      navigate(`/solution`);
                    }}
                  >
                    实时数仓
                  </p>
                  <p
                    onClick={() => {
                      navigate(`/solution`);
                    }}
                  >
                    湖仓一体
                  </p>
                  <p
                    onClick={() => {
                      navigate(`/solution`);
                    }}
                  >
                    存算分离
                  </p>
                  <p
                    onClick={() => {
                      navigate(`/solution`);
                    }}
                  >
                    机器学习
                  </p>
                  <p
                    onClick={() => {
                      navigate(`/solution`);
                    }}
                  >
                    MPP大规模并行数据库
                  </p>
                  <p
                    onClick={() => {
                      navigate(`/solution`);
                    }}
                  >
                    大数据搜索平台和搜索网关
                  </p>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <p
                  className="collapsed btn1"
                  data-bs-toggle="collapse"
                  href="#collapseTwo"
                  onClick={() => setIsCollapsed2(!isCollapsed2)}
                >
                  <span>技术服务</span>
                  {isCollapsed2 ? (
                    <img src={bottom} alt="" width="16" height="16" />
                  ) : (
                    <img src={top} alt="" width="16" height="16" />
                  )}
                </p>
              </div>
              <div
                id="collapseTwo"
                className="collapse"
                data-bs-parent="#accordion"
              >
                <div className="card-body">
                  <p
                    onClick={() => {
                      navigate(`/service`);
                    }}
                  >
                    数据仓库和大数据平台咨询
                  </p>
                  <p
                    onClick={() => {
                      navigate(`/service`);
                    }}
                  >
                    数据仓库和大数据平台建设
                  </p>
                  <p
                    onClick={() => {
                      navigate(`/service`);
                    }}
                  >
                    数据仓库和大数据平台运维支持
                  </p>
                  <p
                    onClick={() => {
                      navigate(`/service`);
                    }}
                  >
                    数据仓库和大数据平台技术培训
                  </p>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <p
                  className="collapsed btn1"
                  data-bs-toggle="collapse"
                  href="#collapseThree"
                  onClick={() => setIsCollapsed(!isCollapsed)}
                >
                  <span>客户案例</span>
                  {isCollapsed ? (
                    <img src={bottom} alt="" width="16" height="16" />
                  ) : (
                    <img src={top} alt="" width="16" height="16" />
                  )}
                </p>
              </div>
              <div
                id="collapseThree"
                className="collapse"
                data-bs-parent="#accordion"
              >
                <div className="card-body">
                  <p
                    onClick={() => {
                      navigate(`/cases`);
                    }}
                  >
                    金融客户实时数仓项目
                  </p>
                  <p
                    onClick={() => {
                      navigate(`/cases`);
                    }}
                  >
                    解决方案
                  </p>
                  <p
                    onClick={() => {
                      navigate(`/cases`);
                    }}
                  >
                    券商大数据分析平台
                  </p>
                  <p
                    onClick={() => {
                      navigate(`/cases`);
                    }}
                  >
                    汽车车联网大数据平台
                  </p>
                  <p
                    onClick={() => {
                      navigate(`/cases`);
                    }}
                  >
                    电信运营商数据分析系统
                  </p>
                  <p
                    onClick={() => {
                      navigate(`/cases`);
                    }}
                  >
                    保险业客户大数据搜索平台
                  </p>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <p
                  className="collapsed btn1"
                  data-bs-toggle="collapse"
                  href="#collapseFour"
                  onClick={() => setIsCollapsed3(!isCollapsed3)}
                >
                  <span>合作伙伴</span>
                  {isCollapsed3 ? (
                    <img src={bottom} alt="" width="16" height="16" />
                  ) : (
                    <img src={top} alt="" width="16" height="16" />
                  )}
                </p>
              </div>
              <div
                id="collapseFour"
                className="collapse"
                data-bs-parent="#accordion"
              >
                <div className="card-body">
                  <p
                    onClick={() => {
                      navigate(`/partner`);
                    }}
                  >
                    SelectDB实时数仓
                  </p>
                  <p
                    onClick={() => {
                      navigate(`/partner`);
                    }}
                  >
                    Cloudera大数据混合平台
                  </p>
                  <p
                    onClick={() => {
                      navigate(`/partner`);
                    }}
                  >
                    Vertica统一分析平台
                  </p>
                  <p
                    onClick={() => {
                      navigate(`/partner`);
                    }}
                  >
                    INFINI Search
                  </p>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <p
                  className="collapsed btn1"
                  data-bs-toggle="collapse"
                  href="#collapseFive"
                  onClick={() => setIsCollapsed4(!isCollapsed4)}
                >
                  <span>关于我们</span>
                  {isCollapsed4 ? (
                    <img src={bottom} alt="" width="16" height="16" />
                  ) : (
                    <img src={top} alt="" width="16" height="16" />
                  )}
                </p>
              </div>
              <div
                id="collapseFive"
                className="collapse"
                data-bs-parent="#accordion"
              >
                <div className="card-body">
                  <p
                    onClick={() => {
                      navigate(`/about`);
                    }}
                  >
                    核心产品
                  </p>
                  <p
                    onClick={() => {
                      navigate(`/about`);
                    }}
                  >
                    核心团队
                  </p>
                  <p
                    onClick={() => {
                      navigate(`/about`);
                    }}
                  >
                    聚焦行业
                  </p>
                  <p
                    onClick={() => {
                      navigate(`/about`);
                    }}
                  >
                    服务体系
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="message">
          <p className="phone">电话：400-001-1205</p>
          <p className="email">邮箱：sales@cdpdata.cn</p>
          <div className="fillIN">
            <p className="color">微信公众号</p>
            <p>
              <img src={code2} alt="" />
            </p>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="footer">
      <div className="footer-case">
        <h1 className="d-none d-md-block">解决方案</h1>
        <h1 className="d-block d-md-none title2">解决方案</h1>
        <p>
          <button
            type="button"
            className="btn btn2 btn-primary px-4 py-sm-1 py-lg-2 text-white d-block d-md-none"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasExample"
          >
            立即咨询
          </button>
        </p>
        <p>
          <button
            type="button"
            className="btn btn-primary px-4 py-sm-1 py-lg-2 text-white d-none d-md-block"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
          >
            立即咨询
          </button>
        </p>
      </div>
      {renderMessage}
      <div className="container footer-trademark">
        <p>公司地址：北京市海淀区学清路甲18号学院8号F2层</p>
        <p>Copyright &copy;2023 北京云攻略信息技术有限公司</p>
        <p>
          <a
            href="https://beian.miit.gov.cn/"
            target="_blank"
            style={{
              color: "white",
              textDecoration: "none",
            }}
          >
            京ICP备2023004931号-1
          </a>
          <a
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502052068"
            style={{
              display: "inline-block",
              lineHeight: "20px",
              height: "20px",
              marginLeft: "10px",
              color: "white",
              textDecoration: "none",
            }}
          >
            <img
              style={{ marginRight: "8px", verticalAlign: "middle" }}
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAC3FBMVEUAAAD+/ODz6Kr//+PeqFfYrn3x167k0JXoxnyaaVzhs2ifaFXbrGLkvFnpyF7v2X/kwm3cp1nhsGfqw3rZqG3ntVzjrFPt3oDjvGnfr2fbnFGti3q0lH7ktoLryXn9v1T4znr/74bnvGz034v+2I/ktoDz6ZLkwY/Dfz7buoftzYbq2IPr0pjs3bLv6KPRrnbKhFv79ND488n/+dDZr4Lx38f/+cH/95f42oL7/97s2Y3++uzw1rvTk3DmuloAAHkBAm7uzWYAAGXktV3qvFr/0ljksE7fo0rWHxhrdocAAIAABHf143Pyy27w1GwGA2jtymHpwWDqxV/qyVyTeFrrwFflwFPislP+xVLpsErbmUfVkEbysETemUTpgj7ThT3XdTg5FDjdhTXWZTDaTCm7TCbTOCLXPiD9LA/QFg3UAwnOAQOEj5kcPpdyhZSptJEACJFpfo4AG44XMInFvYfTvIejmYSVkINyeoJzdoK9un6SjX7FrnwAEHp8enny2HjWwHjKtnhcX3jYzHeNhnfu2HWUjHWsonPNwnH70m9WTm8AAW//723pym3dtmn/0mbnxGa0o2ZeWWb8zGT/4mPtwmJuYmL/22D/vmB5ZGC9kF7/2l0MAF3uyFqnjVn4xFjYnli0mVi5i1jiqVfyyVbmtlbXkVNUOFPlvFLpt1LNrFKjfVLuvlBgHlDsuU/ouU9ONU/ov05ODk7/2E02Gk3jqkqEaUr/tUngjkf7n0bXikb6xERCJETdn0LckUG1gD/ooD3Ulj3jkz3TZT3WjjzOeDqBWDr3pDnglTlMADnbbTf2gjbkbzaTYDZpAjbplzTtcTTEazPXXzOeXzDscS3MPi38jizJWSrVSCrrXynzfCjVdCjZRyjTQCbFUiTlYCPXPSHLPSHWMR/wXh7iRh7GPh3PLBrSIRrWGhfMJxPGJxPRDBG/ABG2ABCxDg7BDAvEGArZAAbJAALPAADa4ry/AAAAPnRSTlMACEIaxqxpAvv7+ff19PDs7Ovn5uXk5OHg29LRy8fEw8G+vLqysaufnJiVk4yDfG9dXFpMSEFBNTApJyEcFO3QiBQAAAFzSURBVBjTYoACZjYZaTZmBmRgxsp9+di21ZysxggxxlmJZy/ev9LXnriIEa5VYUPIray0lOyd+ctVoKKWXFsmXXvu8exO5vsZnnuErcCC5m1e8x5nPXrxOu3TzSqHFguQmI18tff+Jx89HqR7fE5v7q5TtAYK6h8v81p4Ovv6wbAdmRc6HMpddYGCmudrCqbtTn2anHBq15SZ9iUx6kBBkSTfXIfUuBsPL909c9i/uP6EJFAQMJ6j2/Ps32Yk30uIy3jjXxgRLwEUVN07ubTo5LsPr16mXD1X29gZrgUUlN23uD/H28lp09o5TvYVs523ygEFORYsO+TbEOI5cVVTV+XUA1Fu/EBBoxXu0bfnT98cEePa45oUHR7MBHK9IV9Y/BFHFzc7R7/YqF4BsBiDqVBw0NLQoMAAF3c7vwmCEEFln1ZnZxe3wJWx7nZ2jj5qkNDU5l2/ZE3kusjQuRsDxPXYoQFqa6DBIiUmyqKkYwIWAgD35oZAL/mkFwAAAABJRU5ErkJggg=="
            />
            <span>京公网安备 11010502052068号</span>
          </a>
        </p>
      </div>
    </div>
  );
};
