import { useRef, useEffect } from "react";

const FormItems = ({ prefix }) => {
  return (
    <>
      <div className="col-12 col-md-6 mb-3">
        <label htmlFor={`${prefix}-cta-name`} className="form-label required">
          姓名
        </label>
        <input
          type="text"
          className="form-control"
          id={`${prefix}-cta-name`}
          placeholder="请输入"
          required
        />
      </div>
      <div className="col-12 col-md-6 mb-3">
        <label
          htmlFor={`${prefix}-cta-company`}
          className="form-label required"
        >
          公司名称
        </label>
        <input
          type="text"
          className="form-control"
          id={`${prefix}-cta-company`}
          placeholder="请输入"
          required
        />
      </div>
      <div className="col-12 col-md-6 mb-3">
        <label
          htmlFor={`${prefix}-cta-contact`}
          className="form-label required"
        >
          联系方式
        </label>
        <input
          type="text"
          className="form-control"
          id={`${prefix}-cta-contact`}
          placeholder="请输入"
          required
        />
      </div>
      <div className="col-12 col-md-6 mb-3">
        <label htmlFor={`${prefix}-cta-email`} className="form-label">
          邮箱
        </label>
        <input
          type="email"
          className="form-control"
          id={`${prefix}-cta-email`}
          placeholder="请输入"
        />
      </div>
      <div className="col-12 col-md-6 mb-3">
        <label htmlFor={`${prefix}-cta-department`} className="form-label">
          所在部门
        </label>
        <input
          type="text"
          className="form-control"
          id={`${prefix}-cta-department`}
          placeholder="请输入"
        />
      </div>
      <div className="col-12 col-md-6 mb-3">
        <label htmlFor={`${prefix}-cta-interest`} className="form-label">
          感兴趣的内容
        </label>
        <input
          type="text"
          className="form-control"
          id={`${prefix}-cta-interest`}
          placeholder="请输入"
        />
      </div>
    </>
  );
};

const sendEmail = async (prefix) => {
  const name = document.getElementById(`${prefix}-cta-name`).value;
  const company = document.getElementById(`${prefix}-cta-company`).value;
  const contact = document.getElementById(`${prefix}-cta-contact`).value;
  const email = document.getElementById(`${prefix}-cta-email`).value;
  const department = document.getElementById(`${prefix}-cta-department`).value;
  const interest = document.getElementById(`${prefix}-cta-interest`).value;

  console.log({
    name,
    company,
    contact,
    email,
    department,
    interest,
  });

  return fetch("/comment", {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
    },
    body: JSON.stringify({
      name,
      company,
      contact,
      email,
      department,
      interest,
    }),
  });
};

export const ContactUs = () => {
  const formRef = useRef(null);
  const formCanvas = useRef(null);
  const modalRef = useRef(null);
  const modalClose = useRef(null);
  const canvasClose = useRef(null);

  const successToggle = useRef(null);
  const failureToggle = useRef(null);

  const onClickSubmit = async () => {
    formRef.current.classList.remove("was-validated");
    formCanvas.current.classList.remove("was-validated");

    if (window.matchMedia("(max-width: 768px)").matches) {
      if (!formCanvas?.current?.checkValidity?.()) {
        // 校验失败
        formCanvas.current.classList.add("was-validated");
      } else {
        try {
          await sendEmail("canvas");
          successToggle.current.click();
          canvasClose.current.click();
        } catch (err) {
          failureToggle.current.click();
        }
      }
    } else {
      if (!formRef?.current?.checkValidity?.()) {
        // 校验失败
        formRef.current.classList.add("was-validated");
      } else {
        try {
          await sendEmail("web");
          modalClose.current.click();
          successToggle.current.click();
        } catch (err) {
          failureToggle.current.click();
        }
      }
    }
  };

  useEffect(() => {
    const modalIns = document.getElementById("staticBackdrop");
    modalIns.addEventListener("hidden.bs.modal", (e) => {
      formRef.current.classList.remove("was-validated");
    });

    const offcanvasIns = document.getElementById("offcanvasExample");
    offcanvasIns.addEventListener("hidden.bs.offcanvas", () => {
      formCanvas.current.classList.remove("was-validated");
    });

    return () => {
      modalIns.removeEventListener("hidden.bs.modal", (e) => {
        console.log("unmounted", e);
      });
      offcanvasIns.removeEventListener("hidden.bs.offcanvas", (e) => {
        console.log("unmounted", e);
      });
    };
  }, []);

  return (
    <>
      {/* offcanvas */}
      <div
        className="offcanvas offcanvas-bottom"
        tabIndex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header">
          <h5
            className="offcanvas-title text-center"
            id="offcanvasExampleLabel"
          >
            联系我们
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body pb-5" style={{height: "75vh", overflowY: "scroll"}}>
          <div
            className="d-none"
            ref={canvasClose}
            data-bs-dismiss="offcanvas"
          ></div>
          <form className="row" ref={formCanvas} noValidate>
            <FormItems prefix={"canvas"} />
          </form>
          <div
            className="btn btn-primary text-white px-5 w-100 mt-3"
            onClick={onClickSubmit}
          >
            提交
          </div>
        </div>
      </div>

      {/* 下面是对话框 */}
      <div
        className="modal fade"
        style={{ top: 200 }}
        id="staticBackdrop"
        ref={modalRef}
        data-bs-backdrop="static"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                来自 云攻略 的专家将帮您找到最佳方案
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form className="row" ref={formRef} noValidate>
                <FormItems prefix={"web"} />
              </form>
            </div>
            <div
              className="d-none"
              ref={modalClose}
              data-bs-dismiss="modal"
            ></div>
            <div className="modal-footer d-flex justify-content-center">
              <div
                className="btn btn-primary text-white px-5"
                onClick={onClickSubmit}
              >
                提交
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="d-none"
        data-bs-toggle="modal"
        data-bs-target="#successModal"
        ref={successToggle}
      ></div>
      <div
        className="modal fade"
        id="successModal"
        tabIndex="-1"
        aria-labelledby="successModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="successModalLabel">
                发送成功
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              来自云攻略的专家将尽快联系您！
            </div>
            <div className="modal-footer d-flex justify-content-center">
              <div className="btn btn-outline-primary px-5" data-bs-dismiss="modal">
                好的
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="d-none"
        data-bs-toggle="modal"
        data-bs-target="#failureModal"
        ref={failureToggle}
      ></div>
      <div
        className="modal fade"
        id="failureModal"
        tabIndex="-1"
        aria-labelledby="failureModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="failureModalLabel">
                发送失败
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              消息发送失败，请稍后重试！
            </div>
            <div className="modal-footer d-flex justify-content-center">
              <div className="btn btn-danger px-5" data-bs-dismiss="modal">
                好的
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
