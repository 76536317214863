
import { Footer } from "./components/Footer";
import { Navbar } from "./components/Navbar";

import { useEffect } from "react";
import {
  createHashRouter,
  RouterProvider,
  Outlet,
  createBrowserRouter,
} from "react-router-dom";
import { PageSolution } from "./pages/solution";
import { PageService } from "./pages/service";
import { PageCases } from "./pages/cases";
import { PageCaseDetail } from "./pages/cases/detail";
import { PagePartner } from "./pages/partner";
import { PageAbout } from "./pages/about";
import { ContactUs } from "./components/ContactUs";
import { Index } from "./pages/index";
import { useLocation } from "react-router-dom";

const Layout = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="app">
      <Navbar />
      <main>
        <Outlet />
      </main>
      <Footer />
      <ContactUs />
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Index />,
      },
      {
        path: "solution",
        element: <PageSolution />,
      },
      {
        path: "service",
        element: <PageService />,
      },
      {
        path: "cases",
        element: <PageCases />,
      },
      {
        path: "case/:id",
        element: <PageCaseDetail />,
      },
      {
        path: "partner",
        element: <PagePartner />,
      },
      {
        path: "about",
        element: <PageAbout />,
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />
}

export default App;
